.app {
  position: relative;
}

.chat-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #0078d4;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unread-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
}

.friends-list {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  @media (min-width: 600px) {
    width: 250px;
  }
}

.friend-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
}

.chat-window {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 300px;
    right: 220px;
  }
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #0078d4;
  color: white;
  border-radius: 10px 10px 0 0;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
}

.sent {
  background-color: #cce5ff;
}

.received {
  background-color: #e2e3e5;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.chat-input input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.chat-input button {
  padding: 10px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
